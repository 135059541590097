<template>
  <dashboard-layout>
    <div class="flex justify-between items-center mb-6">
      <h5>Administrador</h5>
    </div>
    <hr class="mb-12" />
    <div class="flex flex-col">
      <div class="admin" v-if="admin">
        <div class="container">
          <div class="admin-info">
            <h5>Agregar administrador</h5>
            <div class="input">
              <input
                placeholder="Ingresa el correo del usuario para convertirlo en administrador"
                type="text"
                id="addAdmins"
                v-model="adminEmail"
              />
            </div>
            <span>{{ this.functionMsg }}</span>
            <button @click="addAdmin" class="btn">Añadir</button>
          </div>
        </div>
      </div>
      <hr class="mb-12" />

      <form class="register">
        <h5>Registrar nuevo usuario</h5>
        <div class="inputs">
          <div class="input">
            <input
              type="text"
              placeholder="Usuario"
              class="rounded"
              v-model="username"
            />
            <i class="fa-solid fa-user icon"></i>
          </div>
          <div class="input">
            <input
              type="email"
              placeholder="Correo Electrónico"
              class="rounded"
              v-model="email"
            />
            <i class="fa-solid fa-envelope icon"></i>
          </div>
          <div class="input">
            <input
              type="password"
              placeholder="Contraseña"
              class="rounded"
              v-model="password"
            />
            <i class="fa-solid fa-lock icon"></i>
          </div>

          <div class="input">
            <MultiSelect
              id="multi"
              v-model="selectedPermisos"
              :options="permisos"
              optionLabel="vista"
              placeholder="Seleccionar vistas autorizadas"
            />
          </div>
          <div v-show="error" class="error">{{ this.errorMsg }}</div>
        </div>
        <button @click.prevent="register" class="btn">Crear Cuenta</button>
      </form>
    </div>
  </dashboard-layout>
</template>

<script>
import dashboardLayout from "@/components/Layout.vue";
import { getFunctions, httpsCallable } from "firebase/functions";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { db, firebaseAuth } from "@/firebase";
import { doc, setDoc } from "firebase/firestore";
export default {
  name: "HomeView",
  components: { dashboardLayout },
  data() {
    return {
      modalMessage: "Cambios guardados!",
      modalActive: null,
      adminEmail: "",
      functionMsg: null,
      username: "",
      email: "",
      password: "",
      error: null,
      errorMsg: "",
      selectedPermisos: null,
      permisos: [
        { vista: "Flujos vista", value: "f.r" },
        { vista: "Flujos escritura", value: "f.w" },

        { vista: "Cuentas a Cobrar vista", value: "cc.r" },
        { vista: "Cuentas a Cobrar escritura", value: "cc.w" },

        { vista: "Cuentas a Pagar vista", value: "cp.r" },
        { vista: "Cuentas a Pagar escritura", value: "cp.w" },
      ],
    };
  },
  mounted() {},
  methods: {
    async addAdmin() {
      const functions = getFunctions();
      const addAdmin = await httpsCallable(functions, "addAdminRole");
      const result = await addAdmin({ email: this.adminEmail });
      this.functionMsg = result.data.message;
    },
    async register() {
      // if statement to check if all fields are complete
      if (this.email !== "" && this.password !== "" && this.username !== "") {
        // This is what happens if all fields are complete
        this.error = false;
        this.errorMsg = "";
        createUserWithEmailAndPassword(firebaseAuth, this.email, this.password)
          .then((userCredential) => {
            // Signed in
            const user = userCredential.user.uid;
            const dataBase = db;
            const docRef = doc(dataBase, "users", user);
            setDoc(docRef, {
              nombre: "",
              apellido: "",
              profesion: "",
              uso: "",
              telefono: "",
              dominio: "",
              usuario: this.username,
              email: this.email,
              creado: Date.now(),
              permisos: this.selectedPermisos,
            });
            this.error = true;
            this.errorMsg = "Usuario creado exitosamente";
            return;
          })
          .catch((error) => {
            switch (error.code) {
              case "auth/email-already-in-use":
                this.errorMsg =
                  "El correo ya esta siendo utilizado por un usuario existente";
                break;
              case "auth/invalid-email":
                this.errorMsg = "El correo es invalido";

                break;
              case "auth/operation-not-allowed":
                this.errorMsg = "Operacion no permitida";

                break;
              case "auth/weak-password":
                this.errorMsg = "Por favor crear contrasena mas 'fuerte' ";

                break;
              default:
                this.errorMsg = "Ocurrio un problema, vuelva a intentar";
            }
            // ..
          });

        //this redirecs users to a specific page
      }
      // return error if one of the fields are empty
      this.error = true;
      this.errorMsg = "Por favor llene todos los campos";
      return;
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    admin() {
      return this.$store.state.profileAdmin;
    },
  },
};
</script>

<style lang="scss" scoped>
.admin {
  display: flex;
  justify-content: center;
  .container {
    padding: 60px 25px;
    h5 {
      text-align: center;
    }
    .admin-info {
      border-radius: 8px;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
      padding: 32px;
      background-color: #f1f1f1;
      display: flex;
      flex-direction: column;
      max-width: 600px;
      margin: 32px auto;
      span {
        font-size: 14px;
      }
      .input {
        margin: 16px 0;

        label {
          font-size: 14px;
          display: block;
          padding-bottom: 6px;
        }
        input {
          width: 100%;
          border: none;
          background-color: #f2f7f6;
          padding: 8px;
          height: 50px;
          @media (min-width: 900px) {
          }
          &:focus {
            outline: none;
          }
        }
      }
      button {
        align-self: center;
      }
    }
  }
}
form {
  position: relative;
  justify-content: center;
  align-items: center;
  flex: 1;
  border-radius: 8px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  padding: 32px;
  background-color: #f1f1f1;
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 32px auto;

  h5 {
    text-align: center;
    color: #303030;
    margin-bottom: 40px;
  }
  .inputs {
    width: 100%;
    max-width: 350px;
    .input {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 8px;
      #multi {
        width: 100%;
        background-color: #f2f7f6;
        border: none;
      }
      input {
        width: 100%;
        border: none;
        background-color: #f2f7f6;
        padding: 4px 4px 4px 30px;
        height: 50px;
        &:focus {
          outline: none;
        }
      }
      .icon {
        width: 12px;
        position: absolute;
        left: 6px;
      }
    }
  }
  .forgot-password {
    text-decoration: none;
    color: #000;
    cursor: pointer;
    font-size: 14px;
    margin: 48px 0 32px;
    border-bottom: 1px solid transparent;
    transition: 0.5s ease all;
    &:hover {
      border-color: #303030;
    }
  }
  .angle {
    display: none;
    position: absolute;
    background-color: #fff;
    //   transform: rotateZ(3deg);
    width: 60px;
    right: -30px;
    height: 101%;
    @media (min-width: 900px) {
      display: initial;
    }
  }
}
</style>
