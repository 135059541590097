<template>
  <div class="form-wrap">
    <form class="register">
      <p class="login-register">
        ¿Ya tienes una cuenta?
        <router-link class="router-link" :to="{ name: 'Login' }"
          >Iniciar Sesión</router-link
        >
      </p>
      <h2>Registrarse</h2>
      <div class="inputs">
        <div class="input">
          <input
            type="text"
            placeholder="Usuario"
            class="rounded"
            v-model="username"
          />
          <i class="fa-solid fa-user icon"></i>
        </div>
        <div class="input">
          <input
            type="email"
            placeholder="Correo Electrónico"
            class="rounded"
            v-model="email"
          />
          <i class="fa-solid fa-envelope icon"></i>
        </div>
        <div class="input">
          <input
            type="password"
            placeholder="Contraseña"
            class="rounded"
            v-model="password"
          />
          <i class="fa-solid fa-lock icon"></i>
        </div>
        <div v-show="error" class="error">{{ this.errorMsg }}</div>
      </div>
      <button
        @click.prevent="register"
        class="signInBtn hover:shadow-md rounded"
      >
        Crear Cuenta
      </button>

      <div class="angle"></div>
    </form>
    <div class="background"></div>
  </div>
</template>

<script>
import { createUserWithEmailAndPassword } from "firebase/auth";
import { db, firebaseAuth } from "@/firebase";
import { doc, setDoc } from "firebase/firestore";

export default {
  name: "RegisterUser",
  components: {},
  data() {
    return {
      username: "",
      email: "",
      password: "",
      error: null,
      errorMsg: "",
    };
  },
  methods: {
    // function to register a new user with email and password
    async register() {
      // if statement to check if all fields are complete
      if (this.email !== "" && this.password !== "" && this.username !== "") {
        // This is what happens if all fields are complete
        this.error = false;
        this.errorMsg = "";
        createUserWithEmailAndPassword(firebaseAuth, this.email, this.password)
          .then((userCredential) => {
            // Signed in
            const user = userCredential.user.uid;
            const dataBase = db;
            const docRef = doc(dataBase, "users", user);
            setDoc(docRef, {
              nombre: "",
              apellido: "",
              profesion: "",
              uso: "",
              telefono: "",
              dominio: "",
              usuario: this.username,
              email: this.email,
              creado: Date.now(),
            });
            this.$router.push({ name: "home" });
            return;
          })
          .catch((error) => {
            switch (error.code) {
              case "auth/email-already-in-use":
                this.errorMsg =
                  "El correo ya esta siendo utilizado por un usuario existente";
                break;
              case "auth/invalid-email":
                this.errorMsg = "El correo es invalido";

                break;
              case "auth/operation-not-allowed":
                this.errorMsg = "Operacion no permitida";

                break;
              case "auth/weak-password":
                this.errorMsg = "Por favor crear contrasena mas 'fuerte' ";

                break;
              default:
                this.errorMsg = "Ocurrio un problema, vuelva a intentar";
            }
            // ..
          });

        //this redirecs users to a specific page
      }
      // return error if one of the fields are empty
      this.error = true;
      this.errorMsg = "Por favor llene todos los campos";
      return;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-wrap {
  overflow: hidden;
  display: flex;
  height: 100vh;
  justify-content: center;
  align-self: center;
  margin: 0 auto;
  width: 90%;
  @media (min-width: 900px) {
    width: 100%;
  }
  .register {
    h2 {
      max-width: 350px;
    }
  }
  .login-register {
    margin-bottom: 32px;
    .router-link {
      color: #2b93d1;
    }
  }
  form {
    padding: 0 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    @media (min-width: 900px) {
      padding: 0 50px;
    }
    h2 {
      text-align: center;
      font-size: 32px;
      color: #303030;
      margin-bottom: 40px;
      @media (min-width: 900px) {
        font-size: 40px;
      }
    }
    .inputs {
      width: 100%;
      max-width: 350px;
      .input {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 8px;
        input {
          width: 100%;
          border: none;
          background-color: #f2f7f6;
          padding: 4px 4px 4px 30px;
          height: 50px;
          &:focus {
            outline: none;
          }
        }
        .icon {
          width: 12px;
          position: absolute;
          left: 6px;
        }
      }
    }
    .forgot-password {
      text-decoration: none;
      color: #000;
      cursor: pointer;
      font-size: 14px;
      margin: 48px 0 32px;
      border-bottom: 1px solid transparent;
      transition: 0.5s ease all;
      &:hover {
        border-color: #303030;
      }
    }
    .angle {
      display: none;
      position: absolute;
      background-color: #fff;
      //   transform: rotateZ(3deg);
      width: 60px;
      right: -30px;
      height: 101%;
      @media (min-width: 900px) {
        display: initial;
      }
    }
  }
  .background {
    display: none;
    flex: 2;
    background-color: #213368;
    // background-size: cover;
    // background-image: url("../assets/background.png");
    width: 100%;
    height: 100%;
    @media (min-width: 900px) {
      display: initial;
    }
  }
}
.signInBtn {
  background-color: orange;
  transition: 0.5s ease all;
  padding: 6px 24px;
  font-size: 14px;
  font-weight: 400;
}
.google {
  height: 12px;
  width: auto;
  margin-right: 5px;
}
.oauth {
  width: 250px;
  justify-content: center;
  &:hover {
    background-color: rgba(48, 48, 48, 0.2);
  }
}
.oauth:nth-child(2) {
  margin-top: 12px;
}
</style>
