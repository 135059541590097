import { createStore } from "vuex";
import { db, firebaseAuth } from "@/firebase";
import { doc, getDoc } from "firebase/firestore";

import "firebase/auth";

export default createStore({
  state: {
    editPost: null,
    currentRoute: null,
    user: null,
    userPermisos: [],
    profileEmail: null,
    profileFirstName: null,
    profileLastName: null,
    profileUsername: null,
    profileUso: null,
    profileProfesion: null,
    profileTelefono: null,
    profileDominio: null,
    profileId: null,
    profileInitials: null,
    profileAdmin: null,
  },
  getters: {},
  mutations: {
    updateUser(state, payload) {
      state.user = payload;
    },
    setProfileInfo(state, doc) {
      state.profileId = doc.id;
      state.profileEmail = doc.data().email;
      state.profileFirstName = doc.data().nombre;
      state.profileLastName = doc.data().apellido;
      state.profileUsername = doc.data().usuario;
      state.profileDominio = doc.data().dominio;
      state.profileProfesion = doc.data().profesion;
      state.profileTelefono = doc.data().telefono;
    },
    setProfileInitials(state) {
      state.profileInitials =
        state.profileFirstName.match(/(\b\S)?/g).join("") +
        state.profileLastName.match(/(\b\S)?/g).join("");
    },
    setProfileInfoOuath(state, user) {
      state.profileId = user.id;
      state.profileEmail = user.email;
      state.profileFirstName = user.displayName;
    },
    setProfileAdmin(state, payload) {
      state.profileAdmin = payload;
    },
  },
  actions: {
    async getCurrentUser({ commit, state }) {
      const dataBase = await db;
      const docRef = doc(dataBase, "users", firebaseAuth.currentUser.uid);
      const dbResults = await getDoc(docRef);
      if (dbResults) {
        commit("setProfileInfo", dbResults);
        commit("setProfileInitials");
        let permisos = dbResults.data().permisos;
        permisos?.forEach((doc) => {
          state.userPermisos.push(doc.value);
        });
      }
    },
    async updateUserSettings({ commit, state }) {
      const dataBase = await db.collection("users").doc(state.profileId);
      await dataBase.update({
        nombre: state.profileFirstName,
        apellido: state.profileLastName,
        usuario: state.profileUsername,
        profesion: state.profileProfesion,
        uso: state.profileUso,
        telefono: state.profileTelefono,
        dominio: state.profileDominio,
      });
      commit("setProfileInitials");
    },
    async checkAdmin({ commit }) {
      firebaseAuth.currentUser.getIdTokenResult().then((result) => {
        if (result.claims.admin) {
          let admin = result.claims.admin;
          commit("setProfileAdmin", admin);
          return;
        }
      });
    },
  },
  modules: {},
});
