import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import CuentasCobrar from "@/views/CuentasCobrarView.vue";
import CuentasPagar from "@/views/CuentasPagarView.vue";
import Login from "@/views/Login.vue";
import Register from "@/views/Register.vue";
import Admin from "@/views/AdminView.vue";
import { firebaseAuth } from "@/firebase";
import store from "@/store";
const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "Flujos",
      requiresAuth: true,
      requiresFr: true,
    },
  },
  {
    path: "/cuentas-cobrar",
    name: "cuentasCobrar",
    component: CuentasCobrar,
    meta: {
      title: "Cuentas A Cobrar",
      requiresAuth: true,
      requiresCcr: true,
    },
  },
  {
    path: "/cuentas-pagar",
    name: "cuentasPagar",
    component: CuentasPagar,
    meta: {
      title: "Cuentas A Pagar",
      requiresAuth: true,
      requiresCpr: true,
    },
  },
  {
    path: "/iniciar-sesion",
    name: "Login",
    component: Login,
    meta: {
      title: "Iniciar Sesion",
      requiresAuth: false,
    },
  },
  {
    path: "/registrarse",
    name: "Register",
    component: Register,
    meta: {
      title: "Registrar usuario",
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
    meta: {
      title: "Administrador",
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});
router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title} | Agrosem`;
  next();
});

router.beforeEach(async (to, from, next) => {
  let user = firebaseAuth.currentUser;
  let admin = null;
  let fr,
    fw,
    ccr,
    ccw,
    cpr,
    cpw = null;

  if (user) {
    let token = await user.getIdTokenResult();
    admin = token.claims.admin;
    fr = store.state.userPermisos.includes("f.r");
    fw = store.state.userPermisos.includes("f.w");
    ccr = store.state.userPermisos.includes("cc.r");
    console.log(ccr);
    ccw = store.state.userPermisos.includes("cc.w");
    cpr = store.state.userPermisos.includes("cp.r");
    cpw = store.state.userPermisos.includes("cp.w");
  }
  if (to.matched.some((res) => res.meta.requiresAuth)) {
    if (user) {
      if (to.matched.some((res) => res.meta.requiresAdmin)) {
        if (admin) {
          return next();
        }
        return next({ name: "Login" });
      }
      if (to.matched.some((res) => res.meta.requiresFr)) {
        if (fr || admin) {
          return next();
        }
        return next({ name: "Login" });
      }
      if (to.matched.some((res) => res.meta.requiresFw)) {
        if (fw || admin) {
          return next();
        }
        return next({ name: "Login" });
      }
      if (to.matched.some((res) => res.meta.requiresCcr)) {
        if (ccr || admin) {
          return next();
        }
        return next({ name: "Login" });
      }
      if (to.matched.some((res) => res.meta.requiresCcw)) {
        if (ccw || admin) {
          return next();
        }
        return next({ name: "Login" });
      }
      if (to.matched.some((res) => res.meta.requiresCpr)) {
        if (cpr || admin) {
          return next();
        }
        return next({ name: "Login" });
      }
      if (to.matched.some((res) => res.meta.requiresCpw)) {
        if (cpw || admin) {
          return next();
        }
        return next({ name: "Login" });
      }
      return next();
    }

    return next({ name: "Login" });
  }
  return next();
});

export default router;
