<template>
  <div>
    <Loading v-if="loading" />
    <Navbar v-if="!mobile" />
    <div class="flex pr-5">
      <div class="flex w-72 order-1" v-if="!mobile"></div>
      <div class="flex w-full flex-col order-2 px-16 py-12">
        <Button
          icon="pi pi-align-left"
          @click="toggleVisibleLeft"
          v-if="mobile"
        />
        <Sidebar v-model:visible="visibleLeft" v-if="mobile">
          <div class="flex flex-col content-between h-full">
            <ul>
              <li>A</li>
              <li>A</li>
            </ul>
            <div class="btn flex justify-around items-center" @click="logout">
              Cerrar Sesion
              <i class="pi pi-sign-out ml-2"></i>
            </div>
          </div>
        </Sidebar>
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Loading";
import Navbar from "@/components/DashboardNav";
import { firebaseAuth } from "@/firebase";
import { signOut } from "firebase/auth";

export default {
  name: "dashboardLayout",
  components: {
    Navbar,
    Loading,
  },
  data() {
    return {
      windowWidth: null,
      mobile: null,
      loading: null,
      visibleLeft: null,
    };
  },
  computed: {
    route() {
      return this.$route.name;
    },
  },

  created() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
  },

  methods: {
    checkScreen() {
      this.windowWidth = window.innerWidth;
      this.mobile = this.windowWidth <= 750;
    },
    toggleVisibleLeft() {
      this.visibleLeft = !this.visibleLeft;
    },
    logout() {
      signOut(firebaseAuth)
        .then(() => {
          this.$router.push({ name: "Login" });
        })
        .catch((error) => {
          console.log(error.message);
        });
    },
  },
};
</script>
<style></style>
