<template>
  <dashboard-layout>
    <div class="flex justify-between items-center mb-6">
      <h5>Cuentas a pagar</h5>
    </div>
    <hr class="mb-12" />
    <DataTable
      :value="cuentas_pagar"
      :lazy="true"
      :loading="loading"
      responsiveLayout="scroll"
      v-model:selection="selection"
      :paginator="true"
      selectionMode="single"
      :rows="10"
      class="p-datatable-lg w-full"
      ref="dt"
      dataKey="id"
    >
      <Column
        v-for="col of selecteColumns"
        :field="col.field"
        :header="col.header"
        :key="col.field"
      >
      </Column>
      <Column
        headerStyle="min-width: 4rem; text-align: center"
        bodyStyle="text-align: center; overflow: visible"
      >
        <template #header> ACCIONES </template>
        <template #body="download">
          <div class="flex w-full justify-evenly px-8">
            <Button
              type="button"
              class="downloadBtn mr-2"
              icon="pi pi-download"
              @click.prevent="downloadDoc(download.data.link)"
            ></Button>
            <Button
              type="button"
              class="p-button-sm p-button-icon-only p-button-warning p-button-rounded"
              icon="pi pi-pencil"
              @click.prevent="toggleEditModal(download.data.unique)"
              v-if="admin || cpw"
            ></Button>
            <Button
              type="button"
              class="p-button-sm p-button-icon-only p-button-danger p-button-rounded"
              icon="pi pi-times"
              @click.prevent="
                toggleModal(download.data.unique, download.data.file_name)
              "
              v-if="admin || cpw"
            ></Button>
          </div>
        </template>
      </Column>
    </DataTable>
    <Dialog v-model:visible="display">
      <template #header>
        <h5 class="mr-2">¿Esta seguro que quiere continuar?</h5>
      </template>

      <div class="flex items-center">
        <i class="pi pi-exclamation-triangle mr-2"></i>
        <p>Los archivos eliminados no podran ser recuperados</p>
      </div>

      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          class="p-button-text"
          @click.prevent="toggleModal()"
        />
        <Button
          label="Si"
          class="downloadBtn"
          icon="pi pi-check"
          autofocus
          @click.prevent="deleteDoc(this.toBeDeleted, this.fileName)"
        />
      </template>
    </Dialog>
    <Dialog v-model:visible="displayEdit">
      <template #header>
        <h5 class="mr-2">Mofique la descripcion del archivo</h5>
      </template>

      <div class="flex items-center">
        <input
          type="text"
          v-model="this.descripcion"
          class="p-3 rounded border"
          placeholder="Ingrese descripcion"
        />
      </div>

      <template #footer>
        <Button
          label="Descartar"
          icon="pi pi-times"
          class="p-button-text"
          @click.prevent="toggleEditModal()"
        />
        <Button
          label="Guardar"
          icon="pi pi-check"
          class="downloadBtn"
          autofocus
          @click.prevent="modifyDoc(this.toBeEdited, this.descripcion)"
        />
      </template>
    </Dialog>
  </dashboard-layout>
</template>

<script>
import dashboardLayout from "@/components/Layout.vue";
import { getStorage, ref, deleteObject } from "firebase/storage";
import { updateDoc } from "firebase/firestore";

import {
  collection,
  query,
  getDocs,
  where,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { db } from "@/firebase";

export default {
  name: "HomeView",
  components: { dashboardLayout },
  data() {
    return {
      cuentas_pagar: [],
      columns: null,
      selecteColumns: null,
      loading: null,
      display: null,
      toBeDeleted: null,
      fileName: null,
      toBeEdited: null,
      descripcion: null,
      displayEdit: null,
    };
  },
  mounted() {
    this.getData();
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    admin() {
      return this.$store.state.profileAdmin;
    },
    fr() {
      return this.$store.state.userPermisos.includes("f.r");
    },
    fw() {
      return this.$store.state.userPermisos.includes("f.w");
    },
    ccr() {
      return this.$store.state.userPermisos.includes("cc.r");
    },
    ccw() {
      return this.$store.state.userPermisos.includes("cc.w");
    },
    cpr() {
      return this.$store.state.userPermisos.includes("cp.r");
    },
    cpw() {
      return this.$store.state.userPermisos.includes("cp.w");
    },
  },
  methods: {
    async getData() {
      this.cuentas_pagar = [];

      const q = query(collection(db, "cuentas_pagar"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        this.cuentas_pagar.push(doc.data());
      });
      let cols = Object.keys(this.cuentas_pagar[0]);
      this.columns = cols.map((e) => {
        return {
          field: e,
          header: e.replace(/_+/g, " ").toUpperCase(),
        };
      });
      this.selecteColumns = this.columns.filter((e) => {
        if (
          e.field !== "link" &&
          e.field !== "date" &&
          e.field !== "unique" &&
          e.field !== "category" &&
          e.field !== "file_name"
        ) {
          return e;
        }
      });
    },
    downloadDoc(download) {
      this.loading = true;
      var element = document.createElement("a");
      element.setAttribute("href", download);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      this.loading = false;
    },
    async deleteDoc(unique, file) {
      console.log(file);
      const storage = getStorage();
      const dbRef = collection(db, "cuentas_pagar");
      const docs = query(dbRef, where("unique", "==", unique));
      const snap = await getDocs(docs);
      snap.forEach((item) => {
        deleteDoc(doc(db, "cuentas_pagar", item.id));
      });
      this.cuentas_pagar = this.cuentas_pagar.filter((item) => {
        return item.unique != unique;
      });
      const docRef = ref(storage, file);
      deleteObject(docRef)
        .then(() => {
          if (this.display) {
            this.display = !this.display;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async modifyDoc(unique, descripcion) {
      const dbRef = collection(db, "cuentas_pagar");
      const docs = query(dbRef, where("unique", "==", unique));
      const snap = await getDocs(docs);
      console.log(snap);
      snap.forEach((item) => {
        const docRef = doc(db, "cuentas_pagar", item.id);
        updateDoc(docRef, {
          descripcion: descripcion,
        });
      });
      setTimeout(() => {
        this.displayEdit = !this.displayEdit;
        this.getData();
      }, 1000);
    },
    toggleEditModal(data) {
      this.displayEdit = !this.displayEdit;
      this.toBeEdited = data;
    },
    toggleModal(data, file) {
      this.toBeDeleted = data;
      this.fileName = file;
      this.display = !this.display;
    },
  },
};
</script>

<style lang="scss" scoped></style>
