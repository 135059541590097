<template>
  <router-view v-slot="{ Component }">
    <transition name="page-opacity" mode="out-in">
      <component :is="Component" :key="$route.path" />
    </transition>
  </router-view>
</template>
<script>
import { getAuth, onAuthStateChanged } from "firebase/auth";
export default {
  name: "app",
  created() {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.$store.dispatch("getCurrentUser");
        this.$store.dispatch("checkAdmin");
      }
    });
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
$main-color: #0a0523;
$secondary-color: rgb(145, 149, 197);
$accent-color: #5c4bda;
$accent-color-two: #e1c940;
$neutral-two: #575777;
$neutral-color: #f2f2f2;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}
body {
  background-color: white !important;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.link {
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  color: black;
}

.link-light {
  color: #fff;
}

button,
.router-button {
  transition: 500ms ease all;
  cursor: pointer;
  margin-top: 24px;
  padding: 12px 24px;
  background-color: #303030;
  color: #fff;
  border-radius: 20px;
  border: none;
  text-transform: uppercase;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: rgba(48, 48, 48, 0.7);
  }
}
.button-ghost {
  color: #000;
  padding: 0;
  border-radius: 0;
  margin-top: 50px;
  font-size: 15px;
  font-weight: 500;
  background-color: transparent;
  @media (min-width: 700px) {
    margin-top: 0;
    margin-left: auto;
  }
  i {
    margin-left: 8px;
  }
}
.button-light {
  background-color: transparent;
  border: 2px solid #fff;
  color: #fff;
}
.button-inactive {
  pointer-events: none !important;
  cursor: none !important;
  background-color: rgba(128, 128, 128, 0.5) !important;
}
.error {
  text-align: center;
  font-size: 12px;
  color: red;
}
.blog-card-wrap {
  position: relative;
  background-color: #f1f1f1;
  @media (min-width: 500px) {
    padding: 100px 16px;
  }
  .blog-cards {
    display: grid;
    gap: 32px;
    grid-template-columns: 1fr;
    @media (min-width: 500px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: 900px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (min-width: 1200px) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
.btn {
  display: inline;
  border: none;
  font-size: 1rem;
  font-weight: 400;
  color: black;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid;
  text-transform: uppercase;
  &:focus {
    outline: none;
  }
  &:hover {
    color: rgba(48, 48, 48, 0.7);
    transition: all 0.3s ease-in-out;
    &:hover {
      transform: scale(1.01);
    }
  }
}

.p-datatable .p-column-header-content {
  justify-content: center;
}
.p-selectable-row {
  td {
    text-align: center !important;
  }
}
.downloadBtn {
  background-color: $accent-color !important;
  border-color: $secondary-color !important;
}
.page-opacity-enter-active,
.page-opacity-leave-active {
  transition: 350ms ease all;
}

.page-opacity-enter-from,
.page-opacity-leave-to {
  opacity: 0;
}
</style>
