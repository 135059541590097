import { getApps, initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import store from "@/store";
const firebaseConfig = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_PORJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_APP_ID,
  measurementId: process.env.VUE_APP_MEASUREMENT_ID,
};

const apps = getApps();
const firebaseApp = !apps.length ? initializeApp(firebaseConfig) : apps[0];
const db = getFirestore(firebaseApp);
const analytics = getAnalytics(firebaseApp);
const firebaseAuth = getAuth(firebaseApp);
const getCurrentUser = () =>
  new Promise((resolve, reject) => {
    const unsub = onAuthStateChanged(
      firebaseAuth,
      (user) => {
        unsub();
        resolve(user);
        if (user) {
          store.commit("updateUser", user.uid);
          user.getIdToken(true).then((idToken) => {
            store.commit("updateToken", idToken);
          });
        }
      },
      reject
    );
  });

export { firebaseApp, firebaseAuth, getCurrentUser, analytics, db };
