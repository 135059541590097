<template>
  <div class="form-wrap">
    <form class="login">
      <h2>Iniciar Sesión</h2>

      <div class="inputs">
        <div class="input">
          <input
            type="text"
            placeholder="Correo Electrónico"
            class="rounded"
            v-model="email"
          />
          <i class="fa-solid fa-envelope icon"></i>
        </div>
        <div class="input">
          <input
            type="password"
            placeholder="Contraseña"
            class="rounded"
            v-model="password"
          />
          <i class="fa-solid fa-lock icon"></i>
        </div>
        <div v-show="error" class="error">{{ this.errorMsg }}</div>
      </div>
      <button @click.prevent="signIn" class="signInBtn hover:shadow-md">
        Iniciar Sesión
        <i v-if="loadingLogin" class="pi pi-spinner ml-2"></i>
      </button>

      <div class="w-full">
        <hr class="mt-6 border-b-1 border-gray-400" />
      </div>

      <img src="@/assets/agrosem.jpeg" alt="" class="pic w-1/2" />

      <div class="angle"></div>
    </form>

    <div class="background" id="particles-js"></div>
  </div>
</template>

<script>
import { firebaseAuth } from "@/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import "particles.js";

export default {
  name: "IniciarSesion",
  components: {},
  data() {
    return {
      email: "",
      password: "",
      error: null,
      errorMsg: "",
      loadingLogin: null,
    };
  },
  mounted() {
    this.initParticles();
  },
  methods: {
    signIn() {
      this.loadingLogin = true;
      signInWithEmailAndPassword(firebaseAuth, this.email, this.password)
        .then((user) => {
          this.$router.push({ name: "home" });
          console.log(user);
        })
        .catch((error) => {
          switch (error.code) {
            case "auth/user-not-found":
              this.error = true;
              this.errorMsg = "Usuario no encontrado";
              break;
            case "auth/wrong-password":
              this.error = true;
              this.errorMsg = "contraseña incorrecta";

              break;
            default:
              this.error = true;

              this.errorMsg = "Por favor intente de nuevo";
          }
        });
    },
    initParticles() {
      window.particlesJS("particles-js", {
        particles: {
          number: {
            value: 80,
            density: {
              enable: true,
              value_area: 700,
            },
          },
          color: {
            value: "#ffffff",
          },
          shape: {
            type: "circle",
            stroke: {
              width: 0,
              color: "#000000",
            },
            polygon: {
              nb_sides: 5,
            },
          },
          opacity: {
            value: 0.5,
            random: false,
            anim: {
              enable: false,
              speed: 1,
              opacity_min: 0.1,
              sync: false,
            },
          },
          size: {
            value: 3,
            random: true,
            anim: {
              enable: false,
              speed: 40,
              size_min: 0.1,
              sync: false,
            },
          },
          line_linked: {
            enable: true,
            distance: 150,
            color: "#ffffff",
            opacity: 0.4,
            width: 1,
          },
          move: {
            enable: true,
            speed: 6,
            direction: "none",
            random: false,
            straight: false,
            out_mode: "out",
            bounce: false,
            attract: {
              enable: false,
              rotateX: 600,
              rotateY: 1200,
            },
          },
        },
        interactivity: {
          detect_on: "canvas",
          events: {
            onhover: {
              enable: true,
              mode: "grab",
            },
            onclick: {
              enable: true,
              mode: "push",
            },
            resize: true,
          },
          modes: {
            grab: {
              distance: 140,
              line_linked: {
                opacity: 1,
              },
            },
            bubble: {
              distance: 400,
              size: 40,
              duration: 2,
              opacity: 8,
              speed: 3,
            },
            repulse: {
              distance: 200,
              duration: 0.4,
            },
            push: {
              particles_nb: 4,
            },
            remove: {
              particles_nb: 2,
            },
          },
        },
        retina_detect: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$main-color: #0a0523;
$secondary-color: rgb(145, 149, 197);
$accent-color: #5c4bda;
$accent-color-two: #e1c940;
$neutral-two: #575777;
$neutral-color: #f2f2f2;
.form-wrap {
  overflow: hidden;
  display: flex;
  height: 100vh;
  justify-content: center;
  align-self: center;
  margin: 0 auto;
  width: 90%;
  @media (min-width: 900px) {
    width: 100%;
  }
  .login-register {
    margin-bottom: 32px;
    .router-link {
      color: $accent-color;
    }
  }
  form {
    padding: 0 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    @media (min-width: 900px) {
      padding: 0 50px;
    }
    h2 {
      text-align: center;
      font-size: 32px;
      color: #303030;
      margin-bottom: 40px;
      @media (min-width: 900px) {
        font-size: 40px;
      }
    }
    .inputs {
      width: 100%;
      max-width: 350px;
      .input {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 8px;
        input {
          width: 100%;
          border: none;
          background-color: #f2f7f6;
          padding: 4px 4px 4px 30px;
          height: 50px;
          &:focus {
            outline: none;
          }
        }
        .icon {
          width: 12px;
          position: absolute;
          left: 6px;
        }
      }
    }
    .forgot-password {
      text-decoration: none;
      color: #000;
      cursor: pointer;
      font-size: 14px;
      margin: 48px 0 32px;
      border-bottom: 1px solid transparent;
      transition: 0.5s ease all;
      &:hover {
        border-color: #303030;
      }
    }
    .angle {
      display: none;
      position: absolute;
      background-color: #fff;
      //   transform: rotateZ(3deg);
      width: 60px;
      right: -30px;
      height: 101%;
      @media (min-width: 900px) {
        display: initial;
      }
    }
  }
  .background {
    display: none;
    flex: 2;
    background-color: $main-color;
    // background-size: cover;
    // background-image: url("../assets/background.png");
    width: 100%;
    height: 100%;
    @media (min-width: 900px) {
      display: initial;
    }
  }

  .pic {
    position: absolute;
    bottom: 5%;
  }
}
.signInBtn {
  background-color: orange;
  display: flex;
  align-items: center;
  transition: 0.5s ease all;
  padding: 6px 24px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 24px;
}

.oauth {
  width: 250px;
  justify-content: center;
  &:hover {
    background-color: rgba(48, 48, 48, 0.2);
  }
}
.oauth:nth-child(2) {
  margin-top: 12px;
}
</style>
