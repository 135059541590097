<template>
  <nav
    class="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-72 z-10 py-8 px-6"
  >
    <div
      class="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto"
    >
      <div
        class="md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded hidden"
      >
        <router-link :to="{ name: 'home' }" class="flex justify-center mb-2">
          <img src="@/assets/logo.png" alt="logo" class="w-full" />
        </router-link>
        <h6 class="orgName text-center">Agrosem</h6>
        <hr class="my-12 md:min-w-full" />
        <ul
          class="md:flex-col md:min-w-full flex flex-col list-none"
          v-if="admin"
        >
          <li class="flex" v-for="(item, index) in NavItems" :key="index">
            <router-link
              :to="{ name: item.path }"
              class="mb-4 w-full img-pop rounded py-2 px-4"
              :class="{ active: route === item.path }"
            >
              <div class="flex items-center">
                <i :class="item.icon" class="mr-2"></i>

                {{ item.name }}
              </div>
            </router-link>
          </li>
        </ul>

        <ul class="md:flex-col md:min-w-full flex flex-col list-none" v-else>
          <li
            class="flex"
            v-for="(item, index) in NavItems.slice(0, 3)"
            :key="index"
          >
            <router-link
              :to="{ name: item.path }"
              class="mb-4 w-full img-pop rounded py-2 px-4"
              :class="{ active: route === item.path }"
            >
              <div class="flex items-center">
                <i :class="item.icon" class="mr-2"></i>

                {{ item.name }}
              </div>
            </router-link>
          </li>
        </ul>
        <hr class="my-12 md:min-w-full" />

        <ul class="md:flex-col md:min-w-full flex flex-col list-none">
          <li class="flex">
            <router-link to="#" class="mb-4 w-full img-pop rounded py-2 px-4">
              <i class="pi pi-info-circle mr-2"></i>
              Servicio Tecnico
            </router-link>
          </li>
        </ul>
      </div>

      <br />

      <div class="btn flex justify-around items-center" @click="logout">
        Cerrar Sesion
        <i class="pi pi-sign-out"></i>
      </div>
    </div>
  </nav>
</template>

<script>
import { firebaseAuth } from "@/firebase";
import { signOut } from "firebase/auth";
export default {
  name: "dashboardNav",
  props: ["data"],
  components: {},
  data() {
    return {
      NavItems: [
        {
          name: "Flujos",
          path: "home",
          icon: "pi pi-desktop",
        },
        {
          name: "Cuentas a Cobrar",
          path: "cuentasCobrar",
          icon: "pi pi-money-bill",
        },
        {
          name: "Cuentas a Pagar ",
          path: "cuentasPagar",
          icon: "pi pi-sort-numeric-down-alt",
        },
        {
          name: "Administrador",
          path: "Admin",
          icon: "pi pi-cog",
        },
      ],
    };
  },

  methods: {
    logout() {
      signOut(firebaseAuth)
        .then(() => {
          this.$router.push({ name: "Login" });
        })
        .catch((error) => {
          console.log(error.message);
        });
    },
  },
  mounted() {},
  computed: {
    route() {
      return this.$route.name;
    },
    user() {
      return this.$store.state.user;
    },
    admin() {
      return this.$store.state.profileAdmin;
    },
    fr() {
      return this.$store.state.userPermisos.includes("f.r");
    },
    fw() {
      return this.$store.state.userPermisos.includes("f.w");
    },
    ccr() {
      return this.$store.state.userPermisos.includes("cc.r");
    },
    ccw() {
      return this.$store.state.userPermisos.includes("cc.w");
    },
    cpr() {
      return this.$store.state.userPermisos.includes("cp.r");
    },
    cpw() {
      return this.$store.state.userPermisos.includes("cp.w");
    },
  },
};
</script>

<style lang="scss" scoped>
$main-color: #0a0523;
$secondary-color: rgb(145, 149, 197);
$accent-color: #5c4bda;
$accent-color-two: #e1c940;
$neutral-two: #575777;
$neutral-color: #f2f2f2;
nav {
  background-color: $main-color;
  color: $neutral-color;
  .orgName {
    color: $accent-color-two;
  }
  .img-pop {
    &:hover {
      background-color: rgba(242, 242, 242, 0.1);
    }
  }
  .btn {
    color: $neutral-color;
  }
}
.btn {
  cursor: pointer;
}
.active {
  background-color: rgba(242, 242, 242, 0.1);
}
</style>
